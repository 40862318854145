// $theme-colors: (
//   primary: #F96900,
//   warning: #f39c12
// );
$theme-color: (
//   primary: #F96900,
  warning: #f39c12
);
// NEW THEME
$templateColour: blue;
// $darkblue: #277bad;
// $color: gray;

// All the imports happen here
$primary:       #1966B0;
$secondary:     #F49E4C;
$text-muted:    #222222;


// functions.scss - Bootstrap functions
// @import "bootstrap/functions"; 
@import "~bootstrap/scss/functions";

// your variables + overrides of Bootstrap variables 
// @import "user/user-variables"; 

// custom variables + overrides of Bootstrap variables for this theme
@import "custom/variables"; 

// bootstrap main
// @import "bootstrap";
@import "~bootstrap/scss/bootstrap";
@import '~nouislider';
@import '~leaflet/dist/leaflet.css';
@import '~magnific-popup/dist/magnific-popup.css';
@import '~swiper/dist/css/swiper.min';

// 3rd party plugin variables
@import "custom/variables-3rd-party";

// theme components
@import "custom/theme";

// your components
@import "user/user";
// @import "public/fontawesome.all.min";
@import '~font-awesome/css/font-awesome.min.css'; // font-awesome 4.7.0 from node_modules

/// for bootstrap 5
// // functions.scss - Bootstrap functions
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/mixins';

// // your variables + overrides of Bootstrap variables 
// $primary:       #ef5285; 
// $secondary:     #429244;
// // $font-family-sans-serif:        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// // $font-family-heading:           -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// // custom variables + overrides of Bootstrap variables for this theme
// @import 'public/variables';
// @import 'public/utilities';

// @import 'public/nouislider';
// @import '../vendor/nouislider.min.css';

// END NEW THEME


// @import './variables';
// @import './home';
// @import 'public/rentals';
// @import 'public/devise';
// @import 'public/footer';
// @import 'public/pages';
@import 'public/datepicker';
// @import 'public/reviews';
// @import 'public/reservations';
// @import 'public/socials';
// @import 'public/contactus';
// @import 'public/cookies_bar';

// .navbar-nav {
//   flex-direction: row;
// }


@include media-breakpoint-down(md) {
  #masterhead,
  #content,
  .container {
    max-width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .ownerbutton {
    display: none;
  }
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.alert-success {
  background-color: #18bc9c;
  border-color: #18bc9c;
  color: #fff;
}

/* bootstrap normally does this part for you, but for some reason it's not being applied */
select {
  border: 1px solid #999;
  border-radius: 8px;
  padding:8px;
}

// $select-arrow: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-chevron-down-16.png");
$select-arrow: url("data:image/svg+xml,<svg viewBox='0 0 16 16' width='16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' clip-rule='evenodd'/></svg>");

.bi {
  width: 1.1rem;
  height: 1.1rem;
}

/* only apply this to firefox: */
@-moz-document url-prefix() {

  /* Normally you can just apply this to all 'select's not just a classs selector */
  select.select {
    /* This removes the native down arrow: */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    /* Add a new down arrow, probably shouldn't hotlink: */
    background-image: $select-arrow;
    background-position: right 8px center;
    background-repeat: no-repeat;
  }
}

/* Safari selects are sort of ugly too.  Unfortunately this applies to both Safari *and* Chrome */
@media (-webkit-min-device-pixel-ratio: 0) {
  select {
    -webkit-appearance: none;
    background-image: $select-arrow !important;
    background-position: right 8px center;
    background-repeat: no-repeat;
    padding-right: 20px;
  }
}

svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  fill: currentColor;
  overflow: hidden;
}