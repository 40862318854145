/*
* ===================================================
*     Daterange Picker
* ===================================================
*/

$colorChecked: lighten(theme-color('primary'), 10%) !default;
$bgActive: theme-color('primary') !default;

.datepicker-container-left,
.datepicker-container-right {
    position: relative;
}

.datepicker-container-left {
    .inline-wrapper {
        position: absolute;
        left: 0;
    }
}

.datepicker-container-right {
    .inline-wrapper {
        position: absolute;
        right: 0;
    }
}

// Daterangepicker CSS
.date-picker {
    width: 170px;
    height: 25px;
    padding: 0;
    border: 0;
    line-height: 25px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: $body-color;
    position: relative;
    z-index: 2;
}

.date-picker-wrapper {
    position: absolute;
    z-index: 1050;
    border: 1px solid $gray-200;
    box-shadow: 0 0rem 1.5rem rgba($black, .175);
    background-color: $gray-100;
    padding: 5px 12px;
    font-size: 12px;
    line-height: 20px;
    color: $gray-500;
}

.dp-clearfix {
    clear: both;
    height: 0;
    font-size: 0;
}

.date-picker-wrapper {
    &.single-date {
        width: auto;
    }

    &.no-shortcuts {
        padding-bottom: 12px;
    }

    &.no-topbar {
        padding-top: 12px;
    }

    .footer {
        font-size: 11px;
        padding-top: 3px;
    }

    b {
        color: $gray-700;
        font-weight: 700;
    }

    .month-name {
        text-transform: uppercase;
    }

    .select-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;

        &:hover {
            text-decoration: underline;
        }
    }

    .month-element {
        display: inline-block;
        vertical-align: middle;
    }

    .select-wrapper select {
        position: absolute;
        margin: 0;
        padding: 0;
        left: 0;
        top: -1px;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        text-transform: inherit;
        color: inherit;
        cursor: pointer;
        appearance: none;
        background: transparent;
        border: 0;
        outline: 0;
        opacity: 0.01;
    }

    .month-wrapper {
        border: 1px solid $gray-200;
        border-radius: 3px;
        background-color: $white;
        padding: 5px;
        cursor: default;
        position: relative;
        _overflow: hidden;

        table {
            width: 190px;
            float: left;

            &.month2 {
                width: 190px;
                float: left;
            }

            th,
            td {
                vertical-align: middle;
                text-align: center;
                line-height: 14px;
                margin: 0px;
                padding: 0px;
            }

            .day {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.5rem;
                height: 2.5rem;
                padding: 3px;
                
                line-height: 1;
                font-size: 12px;
                margin-bottom: 1px;
                color: $gray-500;
                cursor: default;
            }

            .day-subtitle {
                color: inherit;
                opacity: .5;
            }

            div.day {

                &.lastMonth,
                &.nextMonth {
                    color: #999;
                    cursor: default;
                }
            }

            .day.checked {
                background-color: $colorChecked;
                border-radius: 0;
            }

            .week-name {
                height: 20px;
                line-height: 20px;
                font-weight: 100;
                text-transform: uppercase;
            }

            .day.has-tooltip {
                cursor: help !important;

                .tooltip {
                    white-space: nowrap;
                }
            }
        }
    }

    .time label {
        white-space: nowrap;
    }

    .month-wrapper table .day {
        &.toMonth {
            &.valid {
                color: $body-color;
                cursor: pointer;
                &.checked {
                    color: $white;    
                }
            }

            &.hovering {
                background-color: $colorChecked;
                color: $white;
                border-radius: 0;
            }
        }

        &.nextMonth,
        &.lastMonth {
            display: none;
        }

        &.real-today {
            background-color: theme-color('secondary');
            color: $white !important;
            border-radius: 50%;
            &.checked,
            &.hovering {
                background-color: $colorChecked;
            }
        }
    }

    table .caption {
        height: 40px;

        >th {

            &:first-of-type,
            &:last-of-type {
                width: 27px;
            }
        }

        .next,
        .prev {
            padding: 0 5px;
            cursor: pointer;
        }

        .next:hover,
        .prev:hover {
            background-color: #ccc;
            color: white;
        }
    }

    .gap {
        position: relative;
        z-index: 1;
        width: 15px;
        height: 100%;
        background-color: #eee;
        font-size: 0;
        line-height: 0;
        float: left;
        top: -5px;
        margin: 0 10px -10px;
        visibility: hidden;
        height: 0;

        .gap-lines {
            height: 100%;
            overflow: hidden;
        }

        .gap-line {
            height: 15px;
            width: 15px;
            position: relative;

            .gap-1 {
                z-index: 1;
                height: 0;
                border-left: 8px solid white;
                border-top: 8px solid #eee;
                border-bottom: 8px solid #eee;
            }

            .gap-2 {
                position: absolute;
                right: 0;
                top: 0px;
                z-index: 2;
                height: 0;
                border-left: 8px solid transparent;
                border-top: 8px solid white;
            }

            .gap-3 {
                position: absolute;
                right: 0;
                top: 8px;
                z-index: 2;
                height: 0;
                border-left: 8px solid transparent;
                border-bottom: 8px solid white;
            }
        }

        .gap-top-mask {
            width: 6px;
            height: 1px;
            position: absolute;
            top: -1px;
            left: 1px;
            background-color: #eee;
            z-index: 3;
        }

        .gap-bottom-mask {
            width: 6px;
            height: 1px;
            position: absolute;
            bottom: -1px;
            left: 7px;
            background-color: #eee;
            z-index: 3;
        }
    }

    .selected-days {
        display: none;
    }

    .drp_top-bar {
        line-height: 1.4;
        position: relative;
        padding: 10px 40px 10px 0;

        .error-top,
        .normal-top {
            display: none;
        }

        .default-top {
            display: block;
        }

        &.error {
            .default-top {
                display: none;
            }

            .error-top {
                display: block;
                color: red;
            }
        }

        &.normal {
            .default-top {
                display: none;
            }

            .normal-top {
                display: block;

                .selection-top {
                    color: #333;
                }
            }
        }

        .apply-btn {
            position: absolute;
            right: 0px;
            top: 6px;
            padding: 3px 5px;
            margin: 0;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;

            &.disabled {
                cursor: pointer;
                color: #606060;
                border: solid 1px #b7b7b7;
                background: #fff;
                background: -moz-linear-gradient(top, #fff, #ededed);
            }
        }
    }

    .time {
        position: relative;
    }

    &.single-month .time {
        display: block;
    }

    .time input[type=range] {
        vertical-align: middle;
        width: 129px;
        padding: 0;
        margin: 0;
        height: 20px;
    }

    .time1 {
        width: 180px;
        padding: 0 5px;
        text-align: center;
    }
}

/*time styling*/

.time2 {
    width: 180px;
    padding: 0 5px;
    text-align: center;
}

.date-picker-wrapper {
    .time1 {
        float: left;
    }

    .time2 {
        float: right;
    }

    .hour {
        text-align: right;
    }
}

.minute {
    text-align: right;
}

.date-picker-wrapper {
    .hide {
        display: none;
    }

    .first-date-selected,
    .last-date-selected {
        position: relative;
        background-color: $bgActive !important;
        color: white !important;
        border-radius: 50% !important;
        z-index: 1;

        &:after {
            z-index: -5;
            position: absolute;
            display: table-cell;
            content: "";
            left: 50%;
            top: 0;
            background: $bgActive;
            width: 50%;
            height: 100%;
        }
    }

    .last-date-selected {
        &:after {
            left: 0;
        }
    }

    .date-range-length-tip {
        position: absolute;
        margin-top: -4px;
        margin-left: -8px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        display: none;
        background-color: $gray-300;
        padding: 5px 8px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        color: $gray-900;
        z-index: 2;
        font-weight: bold;

        &:after {
            content: '';
            position: absolute;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid $gray-300;
            left: 50%;
            margin-left: -4px;
            bottom: -4px;
        }
    }

    // &.two-months.no-gap {

    //     .month1 .next,
    //     .month2 .prev {
    //         // display: none;  // stefka: why not linkedCalendars?
    //     }
    // }

    .week-number {
        padding: 5px 0;
        line-height: 1;
        font-size: 12px;
        margin-bottom: 1px;
        color: #999;
        cursor: pointer;

        &.week-number-selected {
            color: #49e;
            font-weight: bold;
        }
    }
}