// this is the place for your components / CSS

// home page  ////////////////////////////

.guestspicker {
    @include button-outline-variant($gray-400);
    color: $input-color;
    border: none;
    font-size: $font-size-base;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
}

.date-picker-wrapper {
    .month-wrapper table {
        .day.real-today {
                // background-color: #e83e8c;
                color: #fff !important;
                border-radius: 50% !important;
        }
    }
}

.search-bar {
    .input-label-absolute-right .label-absolute {
        right: 0.7rem;
    }
}

#seo1txt {
    font-size: 17px;
    font-weight: 200;
    text-shadow: rgba(50, 50, 50, 0.7) 1px 1px 3px;
    text-align: center;
    padding-bottom: 10px;
}

#seo2btm h3 {
    font-size: 12px;
    font-weight: 200;
    text-align: center;
}

// show page  ////////////////////////////
// rental

.rental {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
}


#amenities {
    list-style: none;

    li {
        font-size: 0.875rem;
    //     // list-style: none;
        width: 175px;
        display: inline-block;
  
        &.available {
          color: #222;
        }
     }

    .additional_amenities {
        li {
            font-size: 0.875rem;
            width: auto;
            display: inline-block;
        }
    }
}

// inquiry form
#inquiry {
    h5 {
        margin: 0 0 10px 0;
        /* padding: 5px; */
        font-size: 15px;
        text-align: center;
        line-height: 1.5;
    
        strong {
          font-size: 20px;
        }
    }

    #spoken_languages {
        font-size: 12px;
        margin-top: 1em;
        color: #ccc;
    }

    label {
        margin-bottom: 0;
        font-size: 0.8rem;
        color: $text-muted;
    }

    .form-group {
        margin-bottom: 0.1rem;
    }

    #reservation_arrival_date {
        @include media-breakpoint-down(md) {
            font-size: 0.8rem;
        }
    }

    .btn {
        width: 100%;
    }

    #booking_price {
        font-size: 18px;
        font-weight: bold;
        color: $secondary;
    }
}

#conflict_msg {
    font-size: 14px;
    text-align: center;
    color: #FF613B;
}

#max_minstay {
    font-size: 14px;
    text-align: center;
    color: #FF613B;

}

// .gallery-show {
//     // width: 50%;
// }

#prices {
    table {
      width: 100%;

      th, td {
        width: 50%;
        text-align: center;
      }
    }
}

// contact us page  ////////////////////////////

.box_contacts .bi {
    width: 46px;
    height: 46px;
    color: #1cbbb4;
}


//// rentals result page  ////////////////
.gm-style .gm-style-iw-c {
    padding: 0px;
}

#rental-list-card {
  .img-search-thumb {
     min-width: -webkit-fill-available;
     width: inherit;
   }
}

.map_rental {
    position: relative;
    top: 0px;
    width: 250px !important;
    text-decoration: none;
    color: #222;
    height: 100%;
    font-size: 12px;
    display: block;
    padding: 0px;

    figure {
        position: absolute;
        left: 0;
        width: 100%;
        height: 166px;
        overflow: hidden;
    }

    .offer-banner {
        position: absolute;
        top: 20px;
        right: 0;
        width: 20%;
        height: 10%;
        font-weight: bold;
        color: white;
        opacity: 1;
        text-align: center;
        border: 1px solid black;
        align-content: right;
        justify-content: right;
        background-color: orange;
    }

    img {
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }

    .content {
        padding-top: 170px;
        padding-left: 2%;
        text-decoration: none;

        .title {
        font-size: 16px;
        font-weight: bold;
        }

        .rates {
        color: orange;
        }
    }
}

///////////////////////////////
/////   reservation status page

// Place all the styles related to the Public::Reservations controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

$gray-light:             #b4bcc2 !default;   // #999
$brand-info:            #3498DB !default;


#reservation {
  border: 1px solid #CCC;
  margin: 20px;
  align-content: center;

  h4 {
    margin-left: 15px;
    background-color: #F8C471;
    padding: 5px;
  }

  .greeting {
    margin-left: 20px;
  }
  .intro {
    margin-left: 20px;
    margin-bottom: 20px;

    .strong_msg {
      font-size: 17px;
      font-weight: bold;
    }
    .strong_dtls {
      font-size: 15px;
      font-weight: bold;
    }

  }
  .booking_details {
    margin-left: 15px;
    margin-right: 0;
    padding: 10px;
    background-color: #fff5ca;

    .property {
      .label_prp {
        font-weight: 600;
        color: #000;
        margin-top: 5px;
      }
    }

    .res_photo {
      height: 150px;
    }

    .details {

      .label_dtl {
        font-weight: 600;
        color: #000;
        margin-top: 5px;
      }
      .guests {
        width: 48%;
        display: inline-block;
        margin-right: 1.9%;
      }
      .dates {
        width: 100%;
        display: inline-block;
        margin-right: 1.9%;
      }


    }
  }

  .booking_payment {
    margin-left: 15px;
    margin-right: 0;
    padding: 10px;
    background-color: #D6EAF8;

    .current_status {
      display: inline-block;
      margin-top: 16px;
      font-weight: 600;
    }
    .paid {
      color: $blue;
    }
    .accepted {
      color: $green;
    }
    .declined {
      color: $red;
    }
    .cancelled{
      color: $red;
    }
    .expired {
      color: #873600;
    }

    .payment {
      margin-top: 10px;
    }

    .payment_terms {
      font-size: 13px;
    }

  }


}

#cancellation {
  border: 1px solid #CCC;
  margin: 20px;
  align-content: center;
  padding-top: 10px;
  padding-left: 20px;

  h4 {
    background-color: lighten($gray-light, 4.5%);
    padding: 5px;
  }
}

#secure_payments {
  border: 1px solid #CCC;
  margin: 20px;
  align-content: center;
  padding-top: 10px;
  padding-left: 20px;

  h4 {
    background-color: lighten($brand-info, 24.5%);
    // #fff5ca;
    padding: 5px;
  }
}

#contactus {
  margin-left: 10px;
  text-align: center;
  font-size: 15px;
}
