.datepicker {
  background-color: #fff;
  border-radius: 4px;
  align-content: center;
  padding: 0;
  box-shadow: 3px 6px 15px 4px rgba(0,0,0,0.25);

  .active {
    border-radius: 50% !important;
    background-image: linear-gradient(#0c78a7, #0c78a7) !important;
    color: #fff !important;
  }
}

.datepicker-dropdown {
  border-right: #0c78a7;
  border-left: #0c78a7;
  font-size: 0.75rem;
}

.datepicker-dropdown.datepicker-orient-left:after {
  border-bottom-color: #0c78a7;
}

.datepicker-dropdown.datepicker-orient-right:after {
  border-bottom-color: #0c78a7;
}

.datepicker table tr td.today,
span.focused {
  border-radius: 50%;
  background-image: linear-gradient(#FFF3E0, #FFE0B2);
}

thead tr:nth-child(2) {
  background-color: #0c78a7;
}

thead tr:nth-child(3) th {
  font-weight: bold;
  padding: 10px 8px;
  color: #BDBDBD;
}

tbody tr td {
  padding: 5px;
}

tfoot tr:nth-child(2) th {
  padding: 5px;
  border-top: 1px solid #CFD8DC;
}

.cw {
  font-size: 14px;
  background-color: #E8EAF6;
  border-radius: 0px;
  padding: 0px 5px;
  margin-right: 10px solid #fff;
}

.old,
.day,
.new {
  width: 35px !important;
  height: 35px !important;
  border-radius: 0px !important;
}

.day.old,
.day.new {
  color: #E0E0E0 !important;
}

.day.old:hover,
.day.new:hover {
  border-radius: 50%;
}

.old-day:hover,
.day:hover,
.new-day:hover,
.month:hover,
.year:hover,
.decade:hover,
.century:hover {
  border-radius: 50% !important;
  background-color: #eee;
}

.range-start,
.range-end {
  border-radius: 50% !important;
  background-image: linear-gradient(#0c78a7, #0c78a7) !important;
}

.range {
  background-color: #E3F2FD !important;
}

.prev,
.next,
.datepicker-switch {
  border-radius: 0px !important;
  padding: 5px;
  font-size: 1rem;
  opacity: 0.7;
  // color: #fff;
}

.prev:hover,
.next:hover,
.datepicker-switch:hover {
  background-color: inherit !important;
  opacity: 1;
}